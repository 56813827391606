import styled from "styled-components/macro"
import { TextField } from "@material-ui/core"
import { device } from "../../../constants/breakpoints"

export const HeartText = styled.b`
  margin-left: 10px;
  font-size: 16px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`
export const Feature = styled.h1`
  color: #001229;
  width: 80%;
  font-size: 56px;
  font-weight: 700;
  margin-top: ${props => (props.center ? undefined : "auto")};
  text-align: ${props => (props.center ? "center" : "left")};
  @media ${device.desktop} {
    padding-right: 20%;
    font-size: 40px !important;
    margin-left: 0px;
  }
  @media ${device.tablet} {
    padding-right: 20%;
    font-size: 28px !important;
    margin-left: 0px;
  }
`

export const HeroItemHeart = styled.div`
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  margin-top: 20px;
  cursor: pointer;
  width: 224px;
  padding: 2px;
  background: #0e0e0e17;
  border-radius: 10px;
  padding-left: 5px;
  &:hover > svg {
    color: ${({ theme }) => theme.lightblue}!important;
  }
`

export const FeatureOutlineWrapper = styled.div`
  color: #264a79;
  margin-top: auto;
`

export const FeatureOutlineCenter = styled.p`
  color: #264a79;
  margin-right: 200px;
  font-size: 50px;
  padding-bottom: 10px;
  font-weight: 400;
  text-align: right;

  @media ${device.tablet} {
    font-size: 30px;
    margin-right: 0px;
    text-align: center;
    border-bottom-style: none;
  }
`

export const FeatureOutline = styled.p`
  color: #264a79;
  margin-right: 200px;
  font-size: 50px;
  padding-bottom: 10px;
  font-weight: 400;
  text-align: right;
  margin-top: auto;
  border-bottom-color: #264a79;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  @media ${device.tablet} {
    font-size: 30px;
    margin-right: 0px;
    text-align: center;
    border-bottom-style: none;
  }
`

export const FeatureOutlineSubTitle = styled.p`
  text-align: right;
  margin-right: 200px;

  text-align: right;
  @media ${device.tablet} {
    font-size: 22px;
    margin-right: 0px;
    text-align: center;
  }
`

export const Background = styled.div`
  background: url(${props => props.img}) center center/cover no-repeat;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &[title="trattamenti"] {
    align-items: flex-end;
  }

  @media ${device.tablet} {
    &[title="trattamenti"] {
      background: url(${props => props.img});
      background-position: ${props =>
        props.page == "estetica" ? "35%" : "20%"};
      background-repeat: no-repeat;
      background-filter: blur(2px);
      align-items: center;
    }
  }
`

export const BottomOverlayInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
`

export const BottomOverlay = styled.div`
  padding: 10px;
  height: 140px;
  margin-top: auto;
  background: rgba(246, 246, 246, 0.71) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000012;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  & > h3 {
    opacity: 1;
  }

  &[title~="trattamenti"] {
    height: 80px;
  }

  @media ${device.tablet} {
    & > h2 {
      font-size: 22px;
    }
    &[title~="home"] {
      height: auto;

      padding-right: 20%;
      padding-left: 20%;
    }
  }
`

export const BottomOverlayTitle = styled.h1`
  font-weight: 700;
  font-size: 25px;
  padding-top: 10px;

  &::after {
    content: "Cerca il trattamento e scegli il tuo chirurgo estetico";
  }
  @media ${device.tablet} {
    font-size: 21px;

    &::after {
      content: "Trova il tuo specialista";
    }
  }
`

export const SearchContainer = styled.div`
  width: 660px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;

  flex-direction: row;
  display: flex;
  &[title~="trattamenti"] {
    width: 280px;
  }
  @media ${device.tablet} {
    &[title~="home"] {
      flex-direction: column;
      width: 100%;
    }
  }
`
export const SearchWrapper = styled.div`
  background-color: white;
  flex-direction: row;
  justify-items: center;
  display: flex;
  box-shadow: 0px 3px 6px #00000012;
  margin-right: 2.5px;
  @media ${device.tablet} {
    &[title~="home"] {
      margin-bottom: 10px;
      border-radius: 6px;
    }
  }
`

export const SearchLeft = styled.input`
  display: flex;
  outline: none;

  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border-width: 0px;
  font-size: 12px;
  width: 278px;
  margin: 0.2px;
  margin-right: 1px;
  padding-left: 20px;
  &[title~="trattamenti"] {
    width: 220px;
  }
`

export const SearchRight = styled(TextField)`
  display: flex;
  flex: 1;
  :before {
    content: "hello";
  }
`

export const SearchButton = styled.span`
  cursor: pointer;
  width: 50px;
  border-radius: 2px;
  box-shadow: 0px 3px 6px #00000012;
  align-content: center;
  justify-content: center;
  padding: 5px;
  background-color: #337ab7;
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  :hover {
    opacity: 0.9;
  }
  flex: 1;
  @media ${device.tablet} {
    &[title~="home"] {
      display: flex;
      width: 100%;
      border-radius: 6px;
      color: white;
      font-weight: 600;
      font-size: 12px;
      padding: 12px;
      background-image: none;
      &::after {
        content: "CERCA";
      }
    }
  }
`

export const Search = styled.input``

export const Trattamenti = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url(${props => props.img}) center center/cover no-repeat;
  padding-left: 20px;
  padding: 40px;
  height: 500px;
  padding-left: 10%;
`

export const FeatureTrattamenti = styled.h1`
  margin-top: auto;
  color: #001229;
  margin-bottom: 30px;
  font-weight: 700;
  align-content: center;
  justify-content: center;
  @media ${device.tablet} {
    font-size: 20px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
`

export const Summary = styled.h4`
  font-weight: 400;
  max-width: 70%;
  font-family: Poppins;
  font-size: 18px;
  line-height: 22px;
  @media ${device.tablet} {
    max-width: 90%;
    font-size: 13px;
    max-height: 20px;
    overflow: hidden;
  }
`
