import React, { createContext, useState, useContext } from "react"
import "./HeroSection.css"
import {
  Feature,
  BottomOverlayInner,
  BottomOverlay,
  Background,
  SearchButton,
  Search,
  SearchLeft,
  SearchWrapper,
  BottomOverlayTitle,
  SearchContainer,
  FeatureOutline,
  FeatureOutlineSubTitle,
  FeatureOutlineWrapper,
  FeatureOutlineCenter,
  Summary,
  FeatureTrattamenti,
  Trattamenti,
  HeroItemHeart,
  HeartText,
} from "./styles/Hero"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { faHeart as emptyHeart } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { useDispatch, useSelector } from "react-redux"
import {
  addPreferenzaAsync,
  rimuoviPreferenzaAsync,
  selectCurrentUser,
  selectPreferenzaLoading,
  selectUserPref,
} from "../../redux/userSlice"
import { openErrorToast, openInfoToast } from "../../redux/toastSlice"
import { CircularProgress } from "@material-ui/core"

const OpenCtx = createContext()

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const HeroSection = ({ children, ...restProps }) => {
  return <Background  {...restProps}>{children}</Background>
}

HeroSection.Trattamenti = function HerSTrattamenti({ children, ...restProps }) {
  const [successOpen, setSuccessOpen] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  return (
    <OpenCtx.Provider
      value={{
        successOpen,
        setSuccessOpen,
        infoOpen,
        setInfoOpen,
      }}
    >
      <Trattamenti {...restProps}>{children}</Trattamenti>
    </OpenCtx.Provider>
  )
}

HeroSection.InfoToast = function PrefInfoToast({ ...restProps }) {
  const { infoOpen, setInfoOpen } = useContext(OpenCtx)

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setInfoOpen(false)
  }

  return (
    <Snackbar open={infoOpen} autoHideDuration={1000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="info">
        Preferenza rimossa
      </Alert>
    </Snackbar>
  )
}

HeroSection.SuccessToast = function PrefSuccessToast({ ...restProps }) {
  const { successOpen, setSuccessOpen } = useContext(OpenCtx)

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setSuccessOpen(false)
  }

  return (
    <Snackbar open={successOpen} autoHideDuration={1000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="success">
        Preferenza aggiunta con successo!
      </Alert>
    </Snackbar>
  )
}

HeroSection.PrefInnerItemHeart = function PrefPrefInnerItemHeart({ pref }) {
  const user = useSelector(selectCurrentUser)
  const preferences = useSelector(selectUserPref)
  const dispatch = useDispatch()
  const loading = useSelector(selectPreferenzaLoading)
  const isActive = user?.user_email
    ? preferences.find(p => p.id_tra === pref.id_sys)
    : false

  const handleClick = () => {
    if (!user?.user_email) {
      return dispatch(
        openErrorToast("Devi eseguire il login per aggiungere una preferenza!")
      )
    }
    if (!isActive) {
      dispatch(
        addPreferenzaAsync(
          {
            id_contatto: user.user_id,
            id_trattamento: pref.id_sys,
            trattamento: pref.trattamento,
          },
          () => dispatch(openInfoToast("Trattamento aggiunto ai preferiti")),
          () => dispatch(openErrorToast("Oops si è verificato un errore"))
        )
      )
    } else {
      dispatch(
        rimuoviPreferenzaAsync(
          {
            id_utente: user.user_id,
            id_trattamento: pref.id_sys,
          },
          () => dispatch(openInfoToast("Trattamento rimosso dai preferiti")),
          () => dispatch(openErrorToast("Oops si è verificato un errore"))
        )
      )
    }
  }

  if (isActive) {
    return (
      <HeroItemHeart onClick={() => handleClick()}>
        {loading === pref.id_sys ? (
          <CircularProgress size={11} color={"#337ab7"} />
        ) : (
          <FontAwesomeIcon
            style={{ color: "#264a79", fontSize: 14 }}
            icon={faHeart}
          />
        )}
        <HeartText style={{ color: "264a79" }}>AGGIUNTO A PREFERITI</HeartText>
      </HeroItemHeart>
    )
  }
  return (
    <HeroItemHeart onClick={() => handleClick()}>
      {loading === pref.id_sys ? (
        <CircularProgress size={11} color={"#337ab7"} />
      ) : (
        <FontAwesomeIcon
          style={{ color: "#ccd0d4", fontSize: 14 }}
          icon={faHeart}
        />
      )}
      <HeartText style={{ color: "264a79" }}>AGGIUNGI A PREFERITI</HeartText>
    </HeroItemHeart>
  )
}

HeroSection.FeatureTrattamenti = function HerSTrattamenti({
  children,
  ...restProps
}) {
  return <FeatureTrattamenti {...restProps}>{children}</FeatureTrattamenti>
}
HeroSection.Summary = function HeroSummary({ children, ...restProps }) {
  return <Summary {...restProps}>{children}</Summary>
}

HeroSection.Feature = function HeroFeature({ children, ...restProps }) {
  return <Feature {...restProps}>{children}</Feature>
}

HeroSection.FeatureOutlineWrapper = function HeroFeatureOutlineWrapper({
  children,
  ...restProps
}) {
  return (
    <FeatureOutlineWrapper {...restProps}>{children}</FeatureOutlineWrapper>
  )
}

HeroSection.FeatureOutlineCenter = function HeroFeatureOutlineCenter({
  children,
  ...restProps
}) {
  return <FeatureOutlineCenter {...restProps}>{children}</FeatureOutlineCenter>
}

HeroSection.FeatureOutline = function HeroFeatureOutline({
  children,
  ...restProps
}) {
  return <FeatureOutline {...restProps}>{children}</FeatureOutline>
}

HeroSection.FeatureOutlineSubTitle = function HeroFeatureSub({
  children,
  ...restProps
}) {
  return (
    <FeatureOutlineSubTitle {...restProps}>{children}</FeatureOutlineSubTitle>
  )
}

HeroSection.Search = function HeaderSearch({
  searchTerm,
  setSearchTerm,
  ...restProps
}) {
  return <Search {...restProps} />
}

HeroSection.BottomOverlay = function HeaderBottomOverlay({
  children,
  ...restProps
}) {
  return <BottomOverlay {...restProps}>{children}</BottomOverlay>
}

HeroSection.BottomOverlayTitle = function HeaderBottomOverlayTitle({
  children,
  ...restProps
}) {
  return <BottomOverlayTitle {...restProps}>{children}</BottomOverlayTitle>
}

HeroSection.BottomOverlayInner = function HeroBottomOverlayInner({ children }) {
  return <BottomOverlayInner>{children}</BottomOverlayInner>
}

HeroSection.SearchLeft = function HeaderSearchLeft({
  InputProps,
  label,
  inputProps,
  title,
  ...restProps
}) {
  return (
    <SearchWrapper title={title} ref={InputProps.ref}>
      <p
        style={{
          margin: "auto",
          color: "#337AB7",
          fontSize: 12,
          marginLeft: 10,
        }}
      >
        &#9679;
      </p>
      <SearchLeft
        title={title}
        {...restProps}
        {...inputProps}
        placeholder={label}
        type="text"
      />
    </SearchWrapper>
  )
}

HeroSection.SearchButton = function HeaderSearchButton({
  children,
  ...restProps
}) {
  return <SearchButton {...restProps}>{children}</SearchButton>
}

HeroSection.SearchContainer = function HeaderSearchLeft({
  children,
  ...restProps
}) {
  return <SearchContainer {...restProps}>{children}</SearchContainer>
}

export default HeroSection
